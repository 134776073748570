import './App.css';

function App() {
  return (
    <div className="App">
      <div className='subdomains'>
        <a href='http://architektura.idomy.eu'><div className='subdomain'><h1>Architektura</h1></div></a>
        <a href='http://smart.idomy.eu'><div className='subdomain2'><h1>Smart Home</h1></div></a>
      </div>
      <img className='logo2' src='/logo2.png' alt='inteligentne domy'></img>
    </div>
  );
}

export default App;
